import { ArrowRightOutlined, RightOutlined, FacebookOutlined, LinkedinOutlined, TwitterOutlined, GithubOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";

export const Footer = () => {
  return (
    <FooterContainer>
      <LearninBox>
        <p>Learning how to work with this site</p>
        <ArrowButton />
      </LearninBox>
      <FooterBody>
        <InstallyTextContainer>
          <h1>instally</h1>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. the printing and typesetting industry.</p>
        </InstallyTextContainer>
        <FooterLinkContainer>
          <h4>Quick links</h4>
          <a>Slouyion</a>
          <a>Blog</a>
          <a>Pricing</a>
        </FooterLinkContainer>
        <FooterLloginContainer>
          <h4>Log in</h4>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          <FooterLoginInput>
            <input color="white" placeholder="email" />
            <InputRightIcon />
          </FooterLoginInput>
        </FooterLloginContainer>
        <FooterSocialContainer>
          <h4>Social</h4>
          <SocialIconContainer>
            <FacebookOutlined style={{ fontSize: "xx-large", margin: "0px 3px" }} />
            <LinkedinOutlined style={{ fontSize: "xx-large", margin: "0px 3px" }} />
            <TwitterOutlined style={{ fontSize: "xx-large", margin: "0px 3px" }} />
            <GithubOutlined style={{ fontSize: "xx-large", margin: "0px 3px" }} />
          </SocialIconContainer>
        </FooterSocialContainer>
      </FooterBody>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  margin-top: auto;
`;

const FooterLoginInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    border: none;
    background: ${defaultColor.purple.inputLightPurple};
    color: ${defaultColor.white} !important;
    padding: 10px;
    width: 200px;
    border-radius: 7px 0px 0px 7px;
    ::placeholder {
      color: ${defaultColor.purple.darkPurple};
      font-size: ${defaultFontSize.size.larger};
    }
  }
`;

const InputRightIcon = styled(RightOutlined)`
  padding: 8px;
  background: ${defaultColor.white};
  border-radius: 0px 7px 7px 0px;
  color: ${defaultColor.purple.darkPurple};
  cursor: pointer;
  font-size: ${defaultFontSize.size.larger};
`;

const FooterBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  background: ${defaultColor.purple.darkPurple};
  border-radius: 50px 0px 0px 0px;
  padding: 61px 0px 30px;
`;

const InstallyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  p {
    text-align: initial;
    line-height: 1.5;
    font-size: ${defaultFontSize.size.small};
  }
`;

const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  a {
    margin: 8px 0px;
    cursor: pointer;
    font-size: ${defaultFontSize.size.small};
  }
`;

const FooterLloginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
  p {
    text-align: initial;
    line-height: 1.5;
    margin-top: 0px;
    font-size: ${defaultFontSize.size.small};
  }
`;

const FooterSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${defaultColor.white};
  padding: 0px 50px;
`;

const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LearninBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid none;
  width: 600px;
  margin: auto;
  border-radius: 5px 5px 35px 5px;
  padding: 24px 30px;
  background: ${defaultColor.white};
  box-shadow: 1px 2px 18px ${defaultColor.shadow.gray};
  margin-top: 60px;
  position: absolute;

  left: 28%;
  margin-top: -46px;
  p {
    color: ${defaultColor.purple.darkPurple};
    font-weight: ${defaultFontSize.weight.seven};
    font-size: ${defaultFontSize.size.big};
  }
`;

const ArrowButton = styled(ArrowRightOutlined)`
  color: ${defaultColor.white};
  background: ${defaultColor.purple.darkPurple};
  border-radius: 50px;
  padding: 10px;
  font-size: ${defaultFontSize.size.big};
  box-shadow: 1px 2px 16px ${defaultColor.shadow.purple};
  cursor: pointer;
`;
