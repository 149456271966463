import React from "react";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";
import pic1 from "../../../img/pic1.png";
import { Advantage, Suitable, Content } from "./";

export const Landing = () => {
  return (
    <div>
      <IndexBody>
        <LandingBody>
          <LandingLeftItems>
            <h1>Provide your application to the customer with us</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
            </p>
            <button>Get Sendspark Free</button>
          </LandingLeftItems>
          <LandingImage src={pic1} />
        </LandingBody>{" "}
        <PurpleCircle></PurpleCircle>
        <Advantage />
        <LeftPurpleCircle></LeftPurpleCircle>
        <Suitable />
        <Content />
      </IndexBody>
    </div>
  );
};

const PurpleCircle = styled.div`
  width: 570px;
  height: 653px;
  background: ${defaultColor.purple.lightestPurple};
  border-radius: 800px 0px 800px 1000px;
  border: none;
  position: absolute;
  right: -275px;
  rotate: 28deg;
`;

const LeftPurpleCircle = styled.div`
  width: 800px;
  height: 700px;
  background: ${defaultColor.purple.lightestPurple};
  border-radius: 0px 1000px 600px 1000px;
  margin-top: -50px;
  margin-left: -647px;
  border: none;
`;

const IndexBody = styled.div`
  padding: 20px 80px;
`;

const LandingBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin: 50px 0px;
`;

const LandingLeftItems = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: baseline;
  align-items: baseline;
  text-align: initial;
  h1 {
    font-size: ${defaultFontSize.size.biggest};
    font-weight: ${defaultFontSize.weight.eight};
  }
  p {
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    margin: 0px 0px 30px;
    line-height: 1.5;
  }
  button {
    background: ${defaultColor.purple.darkPurple};
    color: ${defaultColor.white};
    padding: 13px 17px;
    border-radius: 5px;
    border: none;
    margin-left: 8px;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
`;

const LandingImage = styled.img`
  display: flex;
  width: 450px;
`;
