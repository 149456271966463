import React from "react";
import { Card, Col, Input, Form, Button } from "antd";
import { MailOutlined, UserOutlined, SmileOutlined } from "@ant-design/icons";
import { defaultColor, defaultFontSize } from "../../../theme";
import styled from "styled-components";

const { TextArea } = Input;

export const ContactUs = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <h1>Contact Us</h1>
      <p>Please use this from to contact us and we will get back to you as soon as possibel</p>
      <CardContainer span={8}>
        <CardBody>
          <FormBody
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <SigninInput size="large" />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="E-mail"
              style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <SigninInput size="large" />
            </Form.Item>
            <Form.Item
              label="Subject"
              name="Subject"
              style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your subject!",
                },
              ]}
            >
              <SigninInput size="large" />
            </Form.Item>
            <Form.Item
              label="Message"
              name="message"
              style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your message!",
                },
              ]}
            >
              <SubjectInput rows={4} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              <SigninButton type="primary" htmlType="submit">
                Send Message
              </SigninButton>
            </Form.Item>
          </FormBody>
        </CardBody>
      </CardContainer>
    </div>
  );
};

const FormBody = styled(Form)`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
    width: 40px !important;
    text-align: center;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    text-align: start;
    margin-left: -40px;
    font-size: ${defaultFontSize.size.small};
  }
`;

const SigninButton = styled(Button)`
  border: none;
  background: none;
  background-color: ${defaultColor.purple.darkPurple};
  padding: 6px 40px;
  border-radius: 10px;
  color: ${defaultColor.white};
  font-size: initial;
  font-weight: ${defaultFontSize.weight.six};
  margin-top: 20px;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  height: 40px;
  :hover {
    background-color: ${defaultColor.purple.middlePurple} !important ;
  }
`;

const CardContainer = styled(Col)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

const CardBody = styled(Card)`
  width: 130%;
  border: none;
  margin-top: 15px;
  .ant-card-body {
    background: ${defaultColor.purple.lightPurple};
    border-radius: 10px;
    padding: 30px 0px 10px;
  }
`;

const SigninInput = styled(Input)`
  width: 100%;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 0px -85px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
`;

const SubjectInput = styled(TextArea)`
  width: 100%;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 0px -85px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
`;
