import React, { useState } from "react";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../theme";
import { Link } from "react-router-dom";

export const Header = () => {
  const [click, setClick] = useState(0);

  return (
    <NavBody>
      <h3>INSTALLY</h3>
      <div>
        <Link to="/contactus">
          <NavWhiteButton onClick={() => setClick(1)} isActive={click === 1}>
            Contact Us
          </NavWhiteButton>
        </Link>
        <Link to="/price">
          <NavWhiteButton onClick={() => setClick(2)} isActive={click === 2}>
            Pricing
          </NavWhiteButton>
        </Link>
        <Link to="/login">
          <NavWhiteButton onClick={() => setClick(3)} isActive={click === 3}>
            Log in
          </NavWhiteButton>
        </Link>
        <Link to="/document">
          <NavWhiteButton onClick={() => setClick(4)} isActive={click === 4}>
            Document
          </NavWhiteButton>
        </Link>
        <Link to="/">
          <NavPurpleButton onClick={() => setClick(0)} isActive={click === 0}>
            Home
          </NavPurpleButton>
        </Link>
      </div>
    </NavBody>
  );
};

const NavBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px 0px;
  h3 {
    font-weight: ${defaultFontSize.weight.nine};
    font-size: ${defaultFontSize.size.big};
    color: ${defaultColor.purple.darkPurple};
  }
`;

const NavWhiteButton = styled.button`
  border: none;
  background: none;
  border-radius: 5px;

  background: ${({ isActive }) => (isActive ? `${defaultColor.gray.lightGray} ` : "")};
  margin: 0px 7px;
  padding: 10px 17px;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.gray.lightGray};
    border-radius: 5px;
    cursor: pointer;
  }
`;

const NavPurpleButton = styled.button`
  background: ${({ isActive }) => (isActive ? `${defaultColor.purple.darkPurple} ` : `  ${defaultColor.purple.middlePurple}`)};
  color: ${defaultColor.white};
  box-shadow: ${({ isActive }) => (isActive ? `0px 7px 9px ${defaultColor.shadow.inputPurpleShadow} ` : "")};
  padding: 8px 14px;
  border-radius: 5px;
  border: none;
  margin-left: 8px;
  cursor: pointer;
  font-size: ${defaultFontSize.size.inherit};
  :hover {
    background: ${defaultColor.purple.darkPurple};
    box-shadow: 0px 7px 9px ${defaultColor.shadow.inputPurpleShadow};
  }
`;
