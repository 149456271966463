import styled from "styled-components";
import pic2 from "../../../../img/pic2.png";
import pic3 from "../../../../img/pic3.png";
import { defaultColor, defaultFontSize } from "../../../../theme";

export const Suitable = () => {
  return (
    <SuitableBody>
      <h1>Who are we suitable for?</h1>
      <SuitableItems>
        <SuitableItemsDetail>
          <h2>Lorem ipsum dolor sit</h2>
          <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of
            using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
          </p>
          <button>Read more</button>
        </SuitableItemsDetail>
        <img src={pic2} />
      </SuitableItems>
      <SuitableItemsRevers>
        <SuitableItemsDetail>
          <h2>Lorem ipsum dolor sit</h2>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
          </p>
          <button>Read more</button>
        </SuitableItemsDetail>
        <img src={pic3} />
      </SuitableItemsRevers>
    </SuitableBody>
  );
};

const SuitableBody = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -550px;
  margin-bottom: 80px;
  h1 {
    font-weight: 800;
  }
`;

const SuitableItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const SuitableItemsRevers = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  img {
    width: 400px;
  }
`;

const SuitableItemsDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: initial;
  width: 600px;
  h2 {
    font-weight: ${defaultFontSize.weight.eight};
  }
  p {
    line-height: 1.5;
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
    margin-bottom: 22px;
  }
  button {
    background: ${defaultColor.purple.darkPurple};
    color: ${defaultColor.white};
    padding: 8px 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: ${defaultFontSize.size.inherit};
  }
`;
