import { Button, Checkbox, Form, Input } from "antd";
import styled from "styled-components";
import { defaultColor, defaultFontSize } from "../../../../theme";

export const Register = ({ setActiveTab }) => {
  const onChange2 = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <FormBody
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <TitleText>Sign Up</TitleText>
      <Form.Item
        label="Username"
        name="username"
        style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
        ]}
      >
        <SigninInput size="large" placeholder="Username" />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="E-mail"
        style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
        rules={[
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <SigninInput size="large" placeholder="E-mail" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        style={{ margin: "auto", width: "90%", marginBottom: "25px" }}
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
          size="large"
          style={{
            marginLeft: "-45px",
            marginBottom: "3px",
            fontSize: "inherit",
            border: "none",
            boxShadow: `${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px`,
          }}
        />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 0,
          span: 119,
        }}
        style={{ minWidth: "100%", margin: "10px 0px 20px" }}
      >
        <TermsConditions>
          <RememberCheckbox onChange={onChange2}>i read and agree to</RememberCheckbox>
          <a>Terms & Conditions</a>
        </TermsConditions>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <SigninButton type="primary" htmlType="submit">
          Create Account
        </SigninButton>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 0,
          span: 116,
        }}
      >
        <CreateAccountContainer>
          <p>Already have an account?</p>
          <a onClick={() => setActiveTab("1")}>Sign in</a>
        </CreateAccountContainer>
      </Form.Item>
    </FormBody>
  );
};

const TitleText = styled.h1`
  margin-bottom: 55px;
`;

const FormBody = styled(Form)`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-col.ant-col-8.ant-form-item-label.css-dev-only-do-not-override-htwhyh {
    width: 40px !important;
    text-align: center;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    text-align: start;
    margin-left: -20px;
    font-size: ${defaultFontSize.size.small};
  }
`;

const SigninInput = styled(Input)`
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  margin: 0px 0px 3px -45px;
  border: none;
  font-size: ${defaultFontSize.size.inherit};
`;

const SigninButton = styled(Button)`
  border: none;
  background: none;
  background-color: ${defaultColor.purple.darkPurple};
  padding: 9px 60px;
  border-radius: 10px;
  color: ${defaultColor.white};
  font-size: initial;
  font-weight: ${defaultFontSize.weight.six};
  margin-top: 20px;
  box-shadow: ${defaultColor.shadow.inputPurpleShadow} 1px 10px 15px;
  height: 40px;
  :hover {
    background-color: ${defaultColor.purple.middlePurple} !important ;
  }
`;

const RememberCheckbox = styled(Checkbox)`
  font-size: ${defaultFontSize.size.smaller} !important;
  color: ${defaultColor.gray.darkGray};
  :hover .ant-checkbox-inner {
    border-color: ${defaultColor.purple.darkPurple} !important;
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: ${defaultColor.purple.darkPurple} !important;
  }
`;

const CreateAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  p {
    color: ${defaultColor.gray.darkGray};
  }
  a {
    color: ${defaultColor.purple.darkPurple};
    padding-left: 5px;
  }
`;

const TermsConditions = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 10px;
  a {
    color: ${defaultColor.purple.darkPurple};
    font-size: ${defaultFontSize.size.smaller};
  }
`;
