import styled from "styled-components";
import { FieldTimeOutlined, CloudDownloadOutlined, ForkOutlined, MessageOutlined } from "@ant-design/icons";
import { defaultColor, defaultFontSize } from "../../../../theme";

export const Advantage = () => {
  return (
    <div>
      <AdvantageBody>
        <h1>why should you use instally?</h1>

        <p>
          Lorem Ipsum is simply dummy text of the <br />
          printing and typesetting industry. Lorem Ipsum has been
          <br /> dummy text ever since the 1500s
        </p>
      </AdvantageBody>
      <AdvantageItems>
        <AdvantageTop>
          <ItemsDetails>
            <FirstItemIcon />
            <h4>Anylatics</h4>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </ItemsDetails>
          <ItemsDetails>
            <FourthItemIcon />
            <h4>24/7 Support</h4>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </ItemsDetails>
        </AdvantageTop>
        <AdvantageBottom>
          <ItemsDetails>
            <SecoundItemIcon />
            <h4>Secure connection</h4>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </ItemsDetails>
          <ItemsDetails>
            <ThirdItemIcon />
            <h4>Cloud backup</h4>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </ItemsDetails>
        </AdvantageBottom>
      </AdvantageItems>
    </div>
  );
};

const AdvantageBody = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: ${defaultFontSize.weight.eight};
    margin-bottom: 50px;
  }
  p {
    width: 470px;
    text-align: center;
    padding: 25px 0px;
    border-radius: 17px;
    line-height: 1.5;
    box-shadow: 1px 2px 18px ${defaultColor.shadow.gray};
    color: ${defaultColor.gray.middleGray};
    font-weight: ${defaultFontSize.weight.five};
  }
`;

const AdvantageBottom = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const AdvantageTop = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const AdvantageItems = styled.div`
  margin-top: -80px;
  position: relative;
`;
const ItemsDetails = styled.div`
  margin: 0px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-bottom: 0px;
  }
  p {
    font-size: ${defaultFontSize.size.smaller};
    color: ${defaultColor.gray.middleGray};
  }
`;

const FirstItemIcon = styled(FieldTimeOutlined)`
  color: ${defaultColor.purple.darkPurple};
  font-size: 40px;
  background: ${defaultColor.purple.lightPurple};
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const SecoundItemIcon = styled(CloudDownloadOutlined)`
  color: #ff8c42;
  font-size: 40px;
  background: rgb(255 221 174);
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const ThirdItemIcon = styled(ForkOutlined)`
  color: #00b4d8;
  font-size: 40px;
  background: #caf0f8;
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;

const FourthItemIcon = styled(MessageOutlined)`
  color: #57cc99;
  font-size: 40px;
  background: #c7f9cc;
  border-radius: 52px;
  padding: 11px;
  align-items: center;
  text-align: center;
`;
